<template>
 <div>
  <svg id="Layer_1" style="enable-background:new 0 0 183 183;" version="1.1" viewBox="0 0 183 183" x="0px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" y="0px">
   <defs>
    <linearGradient gradientTransform="matrix(120.425 0 0 -96.34 -111272.8125 305130.9375)" gradientUnits="userSpaceOnUse" id="Icon_awesome-tractor_00000130607584316694054840000014908001328470795136_" x1="942.122" x2="941.913" y1="3138.1106" y2="3137.0625">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <g id="icon_tractor" transform="translate(-2074 -2768)">
    <g id="icon_glutenfree" transform="translate(216)">
     <g id="Group_450">
      <g id="icon_halal" transform="translate(420 208)">
       <rect :fill="background" height="183" id="Rectangle_193" width="183" x="1438" y="2560"/>
      </g>
     </g>
    </g>
    <linearGradient gradientTransform="matrix(120.425 0 0 -96.34 -111272.8125 305130.9375)" gradientUnits="userSpaceOnUse" id="Icon_awesome-tractor_00000130607584316694054840000014908001328470795136_" x1="942.122" x2="941.913" y1="3138.1106" y2="3137.0625">
     <stop offset="0" stop-color="#A9A9A9"/>
     <stop offset="1" stop-color="#696969"/>
    </linearGradient>
    <path d="   M2204.6,2874.6c-9.1,0-16.6,7.4-16.6,16.6s7.4,16.6,16.6,16.6c9.1,0,16.6-7.4,16.6-16.6l0,0   C2221.2,2882,2213.8,2874.6,2204.6,2874.6z M2204.6,2895.6c-2.5,0-4.5-2-4.5-4.5s2-4.5,4.5-4.5c2.5,0,4.5,2,4.5,4.5l0,0   C2209.2,2893.6,2207.1,2895.6,2204.6,2895.6L2204.6,2895.6z M2219.7,2841.4h-12v-7.6c0-2.6,0.9-5.2,2.5-7.3   c0.8-1.1,0.7-2.7-0.2-3.7l-4.1-4.5c-1.1-1.2-3.1-1.3-4.3-0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-3.6,4.3-5.5,9.8-5.6,15.4v7.6h-19.3   l-10.6-24.6c-1.4-3.3-4.7-5.5-8.3-5.5h-25.1c-5,0-9,4-9,9v27.6c-1.6-1.3-4-1.2-5.4,0.3l-5.9,5.9c-1.6,1.6-1.6,4.2,0,5.9   c0,0,0,0,0,0l1,1c-0.9,1.7-1.7,3.5-2.2,5.4h-1.3c-2.3,0-4.1,1.9-4.1,4.1c0,0,0,0,0,0v8.3c0,2.3,1.9,4.1,4.1,4.1h1.3   c0.6,1.9,1.3,3.7,2.2,5.4l-1,1c-1.6,1.6-1.6,4.2,0,5.9c0,0,0,0,0,0l5.9,5.9c1.6,1.6,4.2,1.6,5.9,0c0,0,0,0,0,0l1-1   c1.7,0.9,3.5,1.7,5.4,2.2v1.4c0,2.3,1.9,4.1,4.1,4.1h8.3c2.3,0,4.1-1.9,4.1-4.1v-1.3c1.9-0.6,3.7-1.3,5.4-2.2l1,1   c1.6,1.6,4.2,1.6,5.9,0c0,0,0,0,0,0l5.9-5.9c1.6-1.6,1.6-4.2,0-5.9c0,0,0,0,0,0l-1-1c0.9-1.7,1.7-3.5,2.2-5.4h1.3   c2.3,0,4.1-1.9,4.1-4.1l0,0v-1.1h15.2c6.4-8.7,18.1-11.5,27.7-6.7l9.6-9.6c1.1-1.1,1.8-2.7,1.8-4.3v-9.6   C2225.7,2844.1,2223,2841.4,2219.7,2841.4C2219.7,2841.4,2219.7,2841.4,2219.7,2841.4z M2138.4,2889.6c-8.3,0-15.1-6.7-15.1-15.1   c0-8.3,6.7-15.1,15.1-15.1s15.1,6.7,15.1,15.1l0,0C2153.5,2882.9,2146.7,2889.6,2138.4,2889.6z M2142.5,2841.4h-7.2v-18.1h20.1   l7.7,18.1L2142.5,2841.4z" id="Icon_awesome-tractor" style="fill:url(#Icon_awesome-tractor_00000130607584316694054840000014908001328470795136_);"/>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>